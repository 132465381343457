'use strict';
var base = require('../product/base');
var dataLayerGTM = require('gtm/gtmDataLayer');
var slickConfigs = require('../config/slickConfigs');

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Show/hide Sort select
 */
function selectToggle() {
    $(this).closest('.select').toggleClass('active');
}

/**
 * Hides Sort select after selecting an option
 */
function selectChoose() {
    var text = $(this).text();
    var select = $(this).closest('.select');
    select.find('.icon-container').removeClass('active');
    $(this).children('.icon-container').addClass('active');
    var currentText = select.find('.select-current');
    currentText.text(text);
    select.removeClass('active');
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

function regularRefinements(ajaxURL, filter) {
    $.ajax({
        url: ajaxURL,
        data: {
            page: $('.grid-footer').data('page-number'),
            selectedUrl: ajaxURL
        },
        method: 'GET',
        success: function (response) {
            parseResults(response);

            var urlEvent = window.location.href;
            var event ='use_filter';
            var content = {
                filter: filter
            }
            dataLayerGTM.filterOrSortingClick(event, urlEvent,content);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

function pageDesignerRefinements(ajaxURL, filter, pageId, targetClass) {
    var pageID = {
        pageId: pageId
    }
    ajaxURL = appendToUrl(ajaxURL, pageID);
    $.ajax({
        url: ajaxURL,
        data: {
            page: $('.grid-footer').data('page-number'),
            selectedUrl: ajaxURL
        },
        method: 'GET',
        success: function (response) {
            $('#maincontent').html($(response).find('#maincontent'));

            // update mattresses layout
            var $updates = $(response).find('.refinement-size ul.content li.selected .refinement-title-value').text();
            var $updatesCollection = $(response).find('.refinement-collection ul.content li.selected .refinement-title-value').text();
            $(document).find('.refinement-size .refinement-header-text').text($updates);
            
            if($updatesCollection.length) {
                $(document).find('.refinement-collection .refinement-header-text').text($updatesCollection);
            }

            var mattressCard = $(response).find('.pd-layout-container');
            mattressCard.each(function () {
                if ($(this).hasClass('mattress-plp') && $(this).find('.mattress-container').length === $(this).find('.mattress-container.hide-on-plp').length) {
                    $(document).find('#'+$(this).attr('id')).hide();
                }
                if($(this).hasClass('mattress-plp') && $(this).find('.mattress-container.hide-on-plp').length) {
                    $(document).find('#'+$(this).attr('id')).find('.mattress-container.hide-on-plp').closest('.region').addClass('d-none hidden').hide().closest('.row').addClass('grid-short-container')
                }
                $(document).find('#'+$(this).attr('id')).find('.mattress-container.show-on-plp').closest('.region').addClass('show-in-slider');

                if ($(document).find('#'+$(this).attr('id')).find('.mattress-container').closest('.region.show-in-slider').length > 1) {
                    $(document).find('#'+$(this).attr('id')).find('.mattress-container').closest('.mattresses-grid').addClass('show-slider')
                }
            })

            //init slider for mobile mattresses 
            initSlider();

            // current options are size or collection
            var sizeOption = '';
            var collectionOption = '';

            // get targetClass from args populate gtm obj
            if (targetClass === '.refinement-collection') {
                collectionOption = $(targetClass).find(":selected").text().trim();
            } else if (targetClass === '.refinement-size') {
                sizeOption = $(targetClass).find(":selected").text().trim();
            }

            var urlEvent = window.location.href;
            var event ='use_filter';
            var content = {
                filter: filter,
                collection: collectionOption,
                size: sizeOption
            }

            dataLayerGTM.filterOrSortingClickPlusValues(event, urlEvent, content);
            $('body').trigger('plp:sizeUpdate');
            $.spinner().stop();           
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

function initSlider() {
    var sliderContainer = $('.mobile-slider-active .experience-commerce_layouts-mobileGrid3r1c .mattresses-grid.show-slider, .mobile-slider-active .experience-commerce_layouts-mobileGrid2r1c .mattresses-grid.show-slider');

    $('.experience-commerce_layouts-mobileGrid3r1c .mobile-3r-1c .row.mx-n2, .experience-commerce_layouts-mobileGrid2r1c .mobile-2r-1c .row.mx-n2').on('init', function(){
        if ($(this).find('.slider-buttons').length === 0) {
            $('<div>', {
                class: 'slider-buttons'
            }).appendTo($(this));

            $($(this).find('.slick-arrow')).appendTo($(this).find('.slider-buttons'));
        }
    });

    if($(window).width() <= 767) {
        sliderContainer.not('.slick-initialized').slick(slickConfigs.shortedMattress);
        sliderContainer.slick('slickGoTo', 0);
    }
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.grid-header-no-product'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

module.exports = {
    filter: function () {
        // Display refinements bar when Menu icon clicked
        $('.container').on('click', 'button.filter-results', function () {
            $('.refinement-bar, .modal-background').show();
            $('.refinement-bar').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
            $('.refinement-bar .close').focus();
        });
    },

    closeRefinements: function () {
        // Refinements close button
        $('.container').on('click', '.refinement-bar button.close, .modal-background', function () {
            $('.refinement-bar, .modal-background').hide();
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
            $('.btn.filter-results').focus();
        });
    },

    resize: function () {
        // Close refinement bar and hide modal background if user resizes browser
        $(window).resize(function () {
            $('.modal-background').hide();
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
        });
    },

    sort: function () {
        // Handle sort order menu selection
        $('body').on('click', '.select-item', function (e) {
            e.preventDefault();
            $.spinner().start();
            var sorting = $(this).data('id');
            $(this).trigger('search:sort', $(this).data('url'));
            $.ajax({
                url: $(this).data('url'),
                data: { selectedUrl: $(this).data('url') },
                method: 'GET',
                success: function (response) {
                    $('.product-grid').empty().html(response);
                    var urlEvent = window.location.href;
                    var event ='use_sort';
                    var content = {
                        sorting: sorting
                    }
                    dataLayerGTM.filterOrSortingClick(event, urlEvent,content);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    showMore: function () {
        // Show more products
        $('.container').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            var showMoreUrl = $(this).data('url');
            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success: function (response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
        $('body').on('click', '.refinement', function () {
            $('.refinement').not(this).removeClass('active');
            $(this).toggleClass('active');
        });

        $('body').mouseup(function (e) {
            var container = $('.refinement');
            if (container.has(e.target).length === 0) {
                container.removeClass('active');
            }
        });
    },

    applyFilter: function () {
        // Handle refinement value selection and reset click
        $('body').on(
            'change',
            'select.refinement',
            function (e) {
                e.preventDefault();
                e.stopPropagation();

                $.spinner().start();
                var ajaxURL;
                var ajaxPageURL;
                var resetLinkURL = $('div.page').data('real-reset-link') || $('button.reset').data('href');
                var selectedText;
                $('div.page').data('real-reset-link', resetLinkURL);
                $(this).trigger('search:filter', e);
                var attributeId = '#' + $(this).find('span').last().attr('id');
                var filter =  $(this).closest('.card').find('.card-header').data('sort-id');
                if ($(this).is('button.reset')) {
                    ajaxURL = $('div.page').data('real-reset-link');
                } else {
                    ajaxURL = $(this).find('option').filter(':selected').data('href');
                    ajaxPageURL = $(this).find('option').filter(':selected').data('pagehref');
                    selectedText = $(this).find('option').filter(':selected').text();
                };
                var isPageDesigner = $('.pd-container-page');
                var targetClass = '.' + e.target.getAttribute("data-id")
                if (isPageDesigner.length > 0) {
                    var pageId = isPageDesigner.data('pageid');
                    pageDesignerRefinements(ajaxPageURL, filter, pageId, targetClass);
                } else {
                    regularRefinements(ajaxURL, filter);
                }

                if(targetClass === '.refinement-size') {
                    var url = new URL(window.location.href);
                    var params = new URLSearchParams(url.search);
                    var size = $(this).find('option').filter(':selected').data('size-id');
                    if (size) {
                        params.set('size', size);
                        url.search = params.toString();
                        window.history.replaceState({}, '', url.toString());
                    }
                }

            });
    },

    showContentTab: function () {
        // Display content results from the search
        $('.container').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.container').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    },
    availability: base.availability,
    addToCart: base.addToCart,

    showSortSelect: function () {
        $('body').on('click', '.select-header', selectToggle);
        $('body').on('click', '.select-item', selectChoose);
    }
};
